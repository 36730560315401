import React from "react";
import { graphql, StaticQuery } from "gatsby";
import QRCode from "qrcode.react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Grid } from "@material-ui/core";
import SculptureContactBg from "../assets/contact/sculptureContactBg.png";
import Underline1 from "../assets/contact/underline1.png";
import Underline2 from "../assets/contact/underline2.png";
import { isMobile } from "react-device-detect";

import "../utils/normalize.css";
import "../utils/css/screen.css";

import { Container } from "../components/styledComponents";
import LineQRImage from "../assets/contact/lineqr.png";
import LineQRImage2 from "../assets/contact/lineqr2.jpg";
import {
  AboutContainer,
  AboutHeader,
  ContactContainer,
  ContactBlock,
  ContactBlockTitle,
  SocailTitle,
  QRBlock,
  BackgroundImage,
  UnderlineImageContact,
  RelativeBox,
  QRImage
} from "../components/Contact/Contact.style";

const ContactData = [
  {
    title: "PHOTOBOOTH",
    email: "thisissculpture@gmail.com",
    line: "@sculpturebangkok",
    url: "https://www.instagram.com/sculpturebangkok/",
    underline: Underline1,
    qrImage: LineQRImage
  },
  {
    title: "RANDOM SCULPTURE CLUB",
    email: "randomsculpture@gmail.com",
    line: "@randomsculpture",
    url: "https://www.instagram.com/sculpturebangkok/",
    underline: Underline2,
    qrImage: LineQRImage2
  }
];

const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <>
      <Layout title={siteTitle} siteBgColor={"white"}>
        <SEO
          title="Locations"
          keywords={[`wedding`, `sculpture`, `งานแต่งงาน`, `ตู้sculpture`]}
        />
        <Container style={{ paddingTop: "53px" }}>
          <BackgroundImage src={SculptureContactBg} />
          <Grid container spacing={0}>
            <Grid item sm={12} lg={12}>
              <AboutContainer>
                <AboutHeader>CONTACT US</AboutHeader>
              </AboutContainer>
            </Grid>
            <Grid item sm={12} lg={12}>
              <ContactContainer>
                <Grid container spacing={0}>
                  {ContactData &&
                    ContactData.length !== 1 &&
                    ContactData.map((item, index) => (
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%"
                        }}
                      >
                        <ContactBlock>
                          <ContactBlockTitle>FOR</ContactBlockTitle>
                          <RelativeBox>
                            <ContactBlockTitle>{item.title}:</ContactBlockTitle>
                            <UnderlineImageContact src={item.underline} />
                          </RelativeBox>
                          {item.email && (
                            <SocailTitle>EMAIL: {item.email}</SocailTitle>
                          )}
                          {item.line && (
                            <SocailTitle>LINE ID: {item.line}</SocailTitle>
                          )}
                          <SocailTitle>Sculpture Bangkok Co., Ltd.</SocailTitle>
                          {item.url && (
                            <QRBlock>
                              {isMobile ? (
                                <QRImage src={item.qrImage} />
                              ) : (
                                <QRImage src={item.qrImage} />
                              )}
                            </QRBlock>
                          )}
                        </ContactBlock>
                      </Grid>
                    ))}
                  {ContactData &&
                    ContactData.length === 1 &&
                    ContactData.map(item => (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <ContactBlock>
                          <ContactBlockTitle>FOR</ContactBlockTitle>
                          <ContactBlockTitle>{item.title}</ContactBlockTitle>
                          <UnderlineImageContact src={Underline2} />
                          {item.email && (
                            <SocailTitle>EMAIL: {item.email}</SocailTitle>
                          )}
                          {item.line && (
                            <SocailTitle>LINE: {item.line}</SocailTitle>
                          )}
                          <SocailTitle>Sculpture Bangkok Co., Ltd.</SocailTitle>
                          {item.url && (
                            <QRBlock>
                              <QRCode value={item.url} />
                            </QRBlock>
                          )}
                        </ContactBlock>
                      </Grid>
                    ))}
                </Grid>
              </ContactContainer>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
);
